/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import Layout from './Layout'
import './Style.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination, Typography, TextField, Grid, InputAdornment, Select, MenuItem, Checkbox } from '@mui/material';
import Paper from '@mui/material/Paper';
import { Link, Navigate } from 'react-router-dom';
import SearchHead from './SearchHead';
import { useSelector, useDispatch } from 'react-redux';

import { ReactComponent as Down } from "./Icons/Down.svg";
import { ReactComponent as Edits } from "./Icons/Edits.svg";
import { ReactComponent as SaveIcos } from "./Icons/SaveIcos.svg";

import { alertActions, announceActions } from '../_actions';
import { changeDateFormatFrench, convertNumberFrench } from '../_helpers';
import { HiOutlineDownload } from 'react-icons/hi'
import { FaEdit } from "react-icons/fa";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Stack, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
// import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { styled } from '@mui/material/styles';
// import { tableCellClasses } from '@mui/material/TableCell';
// import TableCell from '@mui/material/TableCell';
// import TableFooter from '@mui/material/TableFooter';
import { BiEuro } from 'react-icons/bi'
import { MdDeleteOutline } from 'react-icons/md'
import axios from 'axios';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { frFR } from '@mui/x-date-pickers/locales';
import dayjs from 'dayjs';
import 'dayjs/locale/fr.js';
import moment from "moment";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from '@mui/lab';
import InvoiceComp from './Components/invoiceComp';
import PageLoaderComp from '../component/pageLoaderComp';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};
export default function FacturesComp() {
    const dispatch = useDispatch();
    const currentUser = useSelector(state => state.authentication.user);
    const [pageLoading, setPageLoading] = useState(false);
    const [isOpenQuote, setIsOpenQuote] = useState(false);
    const [isOpenInvoice, setIsOpenInvoice] = useState(false);
    const [selectInvoiceNoValues, setSelectInvoiceNoValues] = useState(`FA-${moment(new Date()).format("DD-MM-YYYY")}-${"1".padStart(2, '0')}`);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSend, setIsLoadingSend] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [quoteId, setQuoteId] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [successfull, setSuccessfull] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchQuery, setSearchQuery] = useState('');
    const [userTva, setUserTva] = useState(currentUser && currentUser.UserBusinessDetails && currentUser.UserBusinessDetails.companyArr.vat ? parseFloat(currentUser.UserBusinessDetails.companyArr.vat) / 100 : 0);
    const [messagesData, setMessagesData] = useState({
        "directory_id": currentUser?.UserProDetails?.details?.id || "",
        "society": "",
        "prenom": "",
        "nom": "",
        "address": "",
        "code_postal": "",
        "ville": "",
        "ville_id": "",
        "villeArr": [],
        "telephone": "",
        "email": "",

    });
    const [invoiceFormData, setInvoiceFormData] = useState({
        quote_arr: [{
            prestation: "",
            quantity: 0,
            price_ht: 0,
            sub_total_ht: 0,
        }],
        total_ht: 0,
        total_tva: 0,
        tva_percent:currentUser && currentUser.UserBusinessDetails&& currentUser.UserBusinessDetails.companyArr.vat?parseFloat(currentUser.UserBusinessDetails.companyArr.vat):0,
        total_ttc: 0,
        remise: 0,
        remiseIsChecked: false,
        total_remise_ht: 0,
        invoice_number: `FA-${moment(new Date()).format("DD-MM-YYYY")}-${"1".padStart(2, '0')}`,
        devis_number: `DEV-${moment(new Date()).format("DD-MM-YYYY")}-${"1".padStart(2, '0')}`, /* moment().format("DDMMY")+"_"+id.padStart(8, '0'), */
        devis_date: moment(new Date()).format("YYYY-MM-DD"),
        devis_validity_date: moment(new Date()).format("YYYY-MM-DD"),
        "create_type": "new",
        "create_type_db": "invoice",
        "create_user_type": "no_user"

    });
    const [quoteFormData, setQuoteFormData] = useState({
        quote_arr: [{
            prestation: "",
            quantity: 0,
            price_ht: 0,
            sub_total_ht: 0,
        }],
        total_ht: 0,
        total_tva: 0,
        tva_percent:currentUser && currentUser.UserBusinessDetails&& currentUser.UserBusinessDetails.companyArr.vat?parseFloat(currentUser.UserBusinessDetails.companyArr.vat):0,
        total_ttc: 0,
        remise: 0,
        remiseIsChecked: false,
        total_remise_ht: 0,
        invoice_number: `FA-${moment(new Date()).format("DD-MM-YYYY")}-${"1".padStart(2, '0')}`,
        devis_number: `DEV-${moment(new Date()).format("DD-MM-YYYY")}-${"1".padStart(2, '0')}`, /* moment().format("DDMMY")+"_"+id.padStart(8, '0'), */
        devis_date: moment(new Date()).format("YYYY-MM-DD"),
        devis_validity_date: moment(new Date()).format("YYYY-MM-DD"),
        "create_type": "new",
        "create_type_db": "invoice",
        "create_user_type": "no_user"


    });
    const TabHead = [
        'Date devis',
        'N°Facture',
        'Nom du client',
        'Prestation',
        'Etat',
        'Montant',
        'Actions',
    ]

    const [tabData, setTabData] = useState([]);

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tabData.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const getAllDevise = async () => {
        await getDevis();
        try {
            // const data = await dispatch(announceActions.getAllAnnounce());
            // setTabData(data?.data);
        } catch (error) {
            // Handle error
        }


    }

    const getAllDeviseRef = useRef(getAllDevise);

    useEffect(() => {
        window.scrollTo(0, 0)
        getAllDeviseRef.current();
    }, []);

    useEffect(() => {
        if (!isEditing && isOpenQuote) {
            setMessagesData({
                "directory_id": currentUser?.UserProDetails?.details?.id || "",
                "society": "",
                "prenom": "",
                "nom": "",
                "address": "",
                "code_postal": "",
                "ville": "",
                "ville_id": "",
                "villeArr": [],
                "telephone": "",
                "email": "",

            })
            setInvoiceFormData({
                quote_arr: [{
                    prestation: "",
                    quantity: 0,
                    price_ht: 0,
                    sub_total_ht: 0,
                }],
                total_ht: 0,
                total_tva: 0,
                tva_percent:currentUser && currentUser.UserBusinessDetails&& currentUser.UserBusinessDetails.companyArr.vat?parseFloat(currentUser.UserBusinessDetails.companyArr.vat):0,
                total_ttc: 0,
                remise: 0,
                remiseIsChecked: false,
                total_remise_ht: 0,
                invoice_number: selectInvoiceNoValues,
                devis_number: quoteFormData.devis_number,
                devis_date: moment(new Date()).format("YYYY-MM-DD"),
                devis_validity_date: moment(new Date()).format("YYYY-MM-DD"),
                "create_type": "new",
                "create_type_db": "invoice",
                "create_user_type": "no_user"

            })
            setQuoteFormData({
                quote_arr: [{
                    prestation: "",
                    quantity: 0,
                    price_ht: 0,
                    sub_total_ht: 0,
                }],
                total_ht: 0,
                total_tva: 0,
                tva_percent:currentUser && currentUser.UserBusinessDetails&& currentUser.UserBusinessDetails.companyArr.vat?parseFloat(currentUser.UserBusinessDetails.companyArr.vat):0,
                total_ttc: 0,
                remise: 0,
                remiseIsChecked: false,
                total_remise_ht: 0,
                invoice_number: quoteFormData.invoice_number,
                devis_number: quoteFormData.devis_number,
                devis_date: moment(new Date()).format("YYYY-MM-DD"),
                devis_validity_date: moment(new Date()).format("YYYY-MM-DD"),
                "create_type": "new",
                "create_type_db": "invoice",
                "create_user_type": "no_user"

            })
        }
    }, [isOpenQuote, isOpenInvoice, isEditing]);

    if (!currentUser) {
        return <Navigate to="/" />;
    }

    const handleSearch = () => {
        console.log('Performing search for:', searchQuery);
    };




    const getDevis = async (postalCode, index) => {
        setPageLoading(true)
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}api/prouser/invoice_all/${currentUser && currentUser.data ? currentUser.data.id : ""}/${currentUser && currentUser.data ? currentUser.data.id : ""}`);
            // const response = await axios.get(`${process.env.REACT_APP_API_URL}api/prouser/quote_all/27737/27737`);
            setPageLoading(false)
            //   console.log("getDevis",response);
            if (response.status === 200 && response.data.success) {
                setSelectInvoiceNoValues(response.data.selectInvoiceNoValues);
                setTabData(response.data?.data);
                setQuoteFormData(prevState => ({
                    ...prevState,
                    invoice_number: response.data.selectInvoiceNoValues
                }));
                setInvoiceFormData(prevState => ({
                    ...prevState,
                    invoice_number: response.data.selectInvoiceNoValues

                }));
            } else {

            }
        } catch (error) {
            setPageLoading(false)
            console.error('Error during API call:', error);
            if (error.response && error.response.status === 400 && error.response.data.success === false) {
                // setErrors(error.response.data.msg || 'API call failed');
            }
        }
    };

    // const filteredRows = tabData.filter((row) => {
    //     return Object.values(row).some((value) =>
    //         value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    //     );
    // });
    const filteredRows = Array.isArray(tabData) ? tabData.filter((row) => {
        return Object.values(row).some((value) =>
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        );
    }) : [];
    const handleClick = (e, rowData) => {
        //         setConfirmOpen(true);
        //         setDeleteId(rowData.id);
    };
    const handleChangeUser = async (e) => {
        let { name, value } = e.target;
        // setIsLoading(true)
        setMessagesData(prevState => ({ ...prevState, [name]: value }));

        if (name === "code_postal") {
            await getCityFromPostalType(name, value);
        }
    };
    const handleChecked = async (e) => {
        let { name, value } = e.target;
        if (e.target.checked) {
            let total_remise_ht = parseFloat(invoiceFormData.total_ht) - (parseFloat(invoiceFormData.total_ht) * (0.1))
            let total_tva = (parseFloat(invoiceFormData.total_ht) - (parseFloat(invoiceFormData.total_ht) * (0.1))) * (userTva);
            let total_ttc = ((parseFloat(invoiceFormData.total_ht) - (parseFloat(invoiceFormData.total_ht) * (0.1))) + parseFloat(total_tva)).toFixed(2)
            setQuoteFormData(prevState => ({
                ...prevState,
                remiseIsChecked: e.target.checked,
                remise: 10,
                total_remise_ht: total_remise_ht,
                total_tva: total_tva,
                total_ttc: total_ttc,
                tva_percent:currentUser && currentUser.UserBusinessDetails&& currentUser.UserBusinessDetails.companyArr.vat?parseFloat(currentUser.UserBusinessDetails.companyArr.vat):0,

            }));
            setInvoiceFormData(prevState => ({
                ...prevState,
                remiseIsChecked: e.target.checked,
                remise: 10,
                total_remise_ht: total_remise_ht,
                total_tva: total_tva,
                total_ttc: total_ttc,
                tva_percent:currentUser && currentUser.UserBusinessDetails&& currentUser.UserBusinessDetails.companyArr.vat?parseFloat(currentUser.UserBusinessDetails.companyArr.vat):0,

            }));
        } else {
            setQuoteFormData(prevState => ({
                ...prevState,
                remiseIsChecked: e.target.checked,
                remise: 0,
                total_remise_ht: 0,
                total_ttc: parseFloat(prevState.total_ht) + parseFloat(invoiceFormData.total_ht) * (userTva),
                total_tva: parseFloat(invoiceFormData.total_ht) * (userTva),
                tva_percent:currentUser && currentUser.UserBusinessDetails&& currentUser.UserBusinessDetails.companyArr.vat?parseFloat(currentUser.UserBusinessDetails.companyArr.vat):0,

            }));
            setInvoiceFormData(prevState => ({
                ...prevState,
                remiseIsChecked: e.target.checked,
                remise: 0,
                total_remise_ht: 0,
                total_ttc: parseFloat(prevState.total_ht) + parseFloat(invoiceFormData.total_ht) * (userTva),
                total_tva: parseFloat(invoiceFormData.total_ht) * (userTva),
                tva_percent:currentUser && currentUser.UserBusinessDetails&& currentUser.UserBusinessDetails.companyArr.vat?parseFloat(currentUser.UserBusinessDetails.companyArr.vat):0,

            }));
        }

    };
    const handleChange = async (evt, index) => {
        let { name, value } = evt.target;
        const updatedQuoteArr = quoteFormData.quote_arr.map((item, idx) => {
            if (idx === index) {
                let sub_total_ht = parseFloat(item.quantity) * parseFloat(item.price_ht);
                if (name === "quantity") {
                    sub_total_ht = value.length > 0 && item.price_ht ? parseFloat(item.price_ht) * parseFloat(value) : 0
                } else if (name === "price_ht") {
                    sub_total_ht = value.length > 0 && item.quantity ? parseFloat(item.quantity) * parseFloat(value) : 0
                }
                const updatedItem = {
                    ...item,
                    [name]: value,
                    sub_total_ht: sub_total_ht
                };
                return updatedItem;
            }
            return item;
        });
        setQuoteFormData(prevState => ({
            ...prevState,
            quote_arr: updatedQuoteArr,
            total_ht: (updatedQuoteArr.reduce((acc, curr) => acc + Number(curr.sub_total_ht), 0)).toFixed(2),
            total_tva: (updatedQuoteArr.reduce((acc, curr) => acc + Number(curr.sub_total_ht) * userTva, 0)).toFixed(2),
            total_ttc: (updatedQuoteArr.reduce((acc, curr) => acc + Number(curr.sub_total_ht) * 1.2, 0)).toFixed(2),
            tva_percent:currentUser && currentUser.UserBusinessDetails&& currentUser.UserBusinessDetails.companyArr.vat?parseFloat(currentUser.UserBusinessDetails.companyArr.vat):0,

        }));
        setInvoiceFormData(prevState => ({
            ...prevState,
            quote_arr: updatedQuoteArr,
            total_ht: (updatedQuoteArr.reduce((acc, curr) => acc + Number(curr.sub_total_ht), 0)).toFixed(2),
            total_tva: (updatedQuoteArr.reduce((acc, curr) => acc + Number(curr.sub_total_ht) * userTva, 0)).toFixed(2),
            total_ttc: (updatedQuoteArr.reduce((acc, curr) => acc + Number(curr.sub_total_ht) * 1.2, 0)).toFixed(2),
            tva_percent:currentUser && currentUser.UserBusinessDetails&& currentUser.UserBusinessDetails.companyArr.vat?parseFloat(currentUser.UserBusinessDetails.companyArr.vat):0,

        }));

    }
    const handleRemove = async (evt, type, indexToRemove) => {
        setQuoteFormData(prevState => ({
            ...prevState,
            quote_arr: prevState.quote_arr.filter((item, index) => index !== indexToRemove),
            total_ht: ((prevState.quote_arr.filter((item, index) => index !== indexToRemove)).reduce((acc, curr) => acc + Number(curr.sub_total_ht), 0)).toFixed(2),
            total_tva: ((prevState.quote_arr.filter((item, index) => index !== indexToRemove)).reduce((acc, curr) => acc + Number(curr.sub_total_ht) * userTva, 0)).toFixed(2),
            total_ttc: ((prevState.quote_arr.filter((item, index) => index !== indexToRemove)).reduce((acc, curr) => acc + Number(curr.sub_total_ht) * 1.2, 0)).toFixed(2),
            tva_percent:currentUser && currentUser.UserBusinessDetails&& currentUser.UserBusinessDetails.companyArr.vat?parseFloat(currentUser.UserBusinessDetails.companyArr.vat):0,
        }));
        setInvoiceFormData(prevState => ({
            ...prevState,
            quote_arr: prevState.quote_arr.filter((item, index) => index !== indexToRemove),
            total_ht: ((prevState.quote_arr.filter((item, index) => index !== indexToRemove)).reduce((acc, curr) => acc + Number(curr.sub_total_ht), 0)).toFixed(2),
            total_tva: ((prevState.quote_arr.filter((item, index) => index !== indexToRemove)).reduce((acc, curr) => acc + Number(curr.sub_total_ht) * userTva, 0)).toFixed(2),
            total_ttc: ((prevState.quote_arr.filter((item, index) => index !== indexToRemove)).reduce((acc, curr) => acc + Number(curr.sub_total_ht) * 1.2, 0)).toFixed(2),
            tva_percent:currentUser && currentUser.UserBusinessDetails&& currentUser.UserBusinessDetails.companyArr.vat?parseFloat(currentUser.UserBusinessDetails.companyArr.vat):0,

        }));

    }
    const handleAdd = async (evt, type) => {
        setQuoteFormData(prevState => ({
            ...prevState,
            quote_arr: [...prevState.quote_arr, [{ prestation: "", quantity: "", price_ht: "", sub_total_ht: "" }]]
        }));
        setInvoiceFormData(prevState => ({
            ...prevState,
            quote_arr: [...prevState.quote_arr, [{ prestation: "", quantity: "", price_ht: "", sub_total_ht: "" }]]

        }));
    }

    const editQuote = async (e, quoteData) => {
        /* remise:0,
        total_remise_ht:0,
        invoice_number:`FA-${moment(new Date()).format("DD-MM-YYYY")}-${"1".padStart(2, '0')}`, */
        // console.log("quoteData",quoteData);
        setQuoteId(quoteData.id);
        setIsEditing(true)
        setMessagesData(quoteData.messages_json_parse);
        // quoteData.quote_json_parse.invoice_number=(quoteData.quote_json_parse.devis_number).replace('DEV', 'FA')
        // setQuoteFormData(quoteData.quote_json_parse)  
        if (quoteData.invoice_json_parse) {
            /* quoteData.invoice_json_parse.remise=0
            quoteData.invoice_json_parse.total_remise_ht=0 */
            // quoteData.invoice_json_parse.invoice_number=(quoteData.quote_json_parse.devis_number).replace('DEV', 'FA')
        }
        setInvoiceFormData(quoteData.invoice_json_parse);
        setIsOpenQuote(true)


    }
    const editInvoice = async (e, quoteData) => {
        setQuoteId(quoteData.id);
        setIsEditing(true)
        setMessagesData(quoteData.messages_json_parse);
        quoteData.quote_json_parse.invoice_number = (quoteData.quote_json_parse.devis_number).replace('DEV', 'FA')
        setQuoteFormData(quoteData.quote_json_parse)
        if (quoteData.invoice_json_parse) {
            /* quoteData.invoice_json_parse.remise=0
            quoteData.invoice_json_parse.total_remise_ht=0 */
            quoteData.invoice_json_parse.invoice_number = (quoteData.quote_json_parse.devis_number).replace('DEV', 'FA')
        }
        setInvoiceFormData(quoteData.invoice_json_parse ? quoteData.invoice_json_parse : quoteData.quote_json_parse);
        setIsOpenInvoice(true)


    }
    const handleSave = async (e, saveType, invoice_save_type = "") => {
        e.preventDefault();

        if (saveType === "send" || invoice_save_type === "send") {
            setIsLoadingSend(true)
        } else {
            setIsLoading(true)
        }


        try {
            // Create the request data object
            const requestData = {
                messagesData: messagesData,
                quoteFormData: quoteFormData,
                invoiceFormData: invoiceFormData,
                saveType: saveType,
                quoteId: quoteId,
                isEditing: isEditing,
                invoice_save_type: invoice_save_type,
                "create_type": "new",
                "create_type_db": "invoice",
                "create_user_type": "no_user"
            };
            let post_url = `${process.env.REACT_APP_API_URL}api/prouser/create-invoice`;

            // Use Axios to make the API request
            const response = await axios.post(post_url, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setSuccessfull(true)
            if (response.status === 200) {
                dispatch(alertActions.success("Factures envoyé avec succès"));
                setIsOpenQuote(false)
                setIsOpenInvoice(false)
                getAllDevise()
                // Handle success, e.g., redirect or show a success message
                // navigate(`/openinghours/${params.id}`); // Redirect to the next page on success        
            } else {
                dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!"));
                // Handle error, e.g., show an error message
                // setError("Une erreur s'est produite. Veuillez réessayer plus tard.");
            }
            setIsLoading(false)
            setIsLoadingSend(false)
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
            setIsLoading(false)
            setIsLoadingSend(false)
        } finally {
            setIsLoading(false)
            setIsLoadingSend(false)
        }
    }
    async function getCityFromPostalType(name, value) {
        const getCity = setTimeout(async () => {
            setMessagesData(formData => ({ ...formData, ["ville"]: "", ["villeArr"]: [], ["ville_id"]: "" }));
            if (value.length > 0) {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}api/checkzipcode/${value}`);
                if (response.data && response.data.data) {
                    setMessagesData(formData => ({ ...formData, ["ville"]: response.data.data.city, ["villeArr"]: response.data.data.cityArr, ["ville_id"]: response.data.data.id }));
                }
            }

        }, 1000);
        return () => clearTimeout(getCity);
    }
    return (
        <>
            {pageLoading ?
                <PageLoaderComp />
                : ""}
            <Layout>
                <div className='mb-8 col-span-12 xl:col-span-9'>
                    <div className={isOpenQuote || isOpenInvoice ? "hidden" : "max-w-[1126px] w-full h-full bg-white rounded-[20px]"} >
                        <div className='mainHead min-h-[80px] sm:min-h-[120px] flex sm:flex-row flex-col sm:items-center p-3 sm:p-10 sm:justify-between'>
                            <div>
                            <h1 className="text-lg sm:text-xl md:text-lg xl:text-[22px] 2xl:text-[22px] font-extrabold text-white sm:mt-0 mt-4">Factures</h1>
                            </div>
                            <div className='w-full sm:w-auto flex justify-end'>
                                {/* <SearchHead
                                searchQuery={searchQuery}
                                setSearchQuery={setSearchQuery}
                                onSearch={handleSearch}
                            /> */}
                            </div>
                        </div>

                        <div className='mt-8 sm:px-9 px-4'>
                            <div className='mb-8 flex sm:flex-row flex-col items-center gap-4'>
                                <div className='flex items-center gap-4'>
                                    <h3 className='text-lg font-normal text-primary '>Du</h3>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                        <DatePicker sx={{
                                            "& .MuiInputLabel-root": { color: '#797979', },
                                            "& fieldset": { border: '1px solid #eee' },
                                        }}
                                            slotProps={{ textField: { size: 'small' } }} />
                                    </LocalizationProvider>
                                </div>
                                <div className='flex items-center gap-4'>
                                    <h3 className='text-lg font-normal text-primary  '>au</h3>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                        <DatePicker sx={{
                                            "& .MuiInputLabel-root": { color: '#797979', },
                                            "& fieldset": { border: '1px solid #eee' },
                                        }} slotProps={{ textField: { size: 'small' } }} />
                                    </LocalizationProvider>
                                </div>
                                {/* <button className='text-base font-medium text-white flex items-center justify-center   py-2   rounded-md bg-primary'>Télécharger les factures</button> */}
                                <button onClick={() => {
                                    setIsOpenQuote(true);
                                    setIsEditing(false);
                                }} className='text-base font-medium text-white flex items-center justify-center px-4  py-2 sm:w-auto w-full rounded-md bg-primary'>Créer une facture</button>

                            </div>
                            <div className=' hidden md:block'>
                                <TableContainer component={Paper} sx={{ boxShadow: 'none' }} >
                                    <Table sx={{ minWidth: 650, borderCollapse: 'separate', borderSpacing: ' 0 15px' }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow >
                                                {
                                                    TabHead.map((thItem, i) => {
                                                        return (
                                                            <TableCell key={i} className='text-base font-normal text-black-light whitespace-nowrap' align="center">{thItem}</TableCell>
                                                        )
                                                    })
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody >

                                            {
                                                (rowsPerPage > 0
                                                    ? tabData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : tabData
                                                ).map((tdItem, i) => {
                                                    return (

                                                        <TableRow key={i} >

                                                    <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">{changeDateFormatFrench(tdItem.invoice_json_parse.devis_date)}</TableCell>
                                                    <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">
                                                        {tdItem.invoice_json_parse.invoice_number}
                                                    </TableCell>
                                                    <TableCell className='text-base font-normal text-primary whitespace-nowrap capitalize' align="left">{tdItem.messages_json_parse.nom} {tdItem.messages_json_parse.prenom}</TableCell>
                                                    <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">{tdItem.saveType === 'save' ? `Enregistré` : `Envoyé`}</TableCell>
                                                    <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">{tdItem.invoice_json_parse.quote_arr[0].prestation}</TableCell>
                                                    <TableCell className='text-base font-normal text-primary whitespace-nowrap' align="left">{convertNumberFrench(tdItem.invoice_json_parse.total_ttc)} €</TableCell>


                                                            <TableCell align="center">
                                                                <Stack spacing={1} direction="row" divider={<Divider orientation="vertical" flexItem />} >
                                                                    <Item className='shadow-none bg-transparent cursor-pointer' >
                                                                        {/* <BiEuro size={25} color={tdItem.status === "2" || tdItem.status === 2 ? 'red' : 'green'} /> */}
                                                                        <Link target={"_blank"} alt={tdItem.invoice_json_parse.invoice_number + ".pdf"} to={process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE + 'assets/uploads/invoices_pro/customers/' + tdItem.invoice_json_parse.invoice_number + ".pdf"}>
                                                                            <Down />

                                                                        </Link>
                                                                    </Item>
                                                                    <Item className='shadow-none bg-transparent cursor-pointer' >
                                                                        <Edits onClick={(e) => editQuote(e, tdItem)} />
                                                                    </Item>
                                                                </Stack>
                                                            </TableCell>

                                                        </TableRow>

                                                    )
                                                })
                                            }
                                            {tabData.length <= 0 && (
                                                <TableRow /* style={{ height: 53 * emptyRows }} */>
                                                    <TableCell colSpan={6} ><p className='text-center font-bold capitalize text-lg'>Aucune donnée!</p></TableCell>
                                                </TableRow>
                                            )}

                                        </TableBody>
                                        <TableFooter>
                                            <TableRow>
                                                <TablePagination
                                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                                    count={tabData.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    SelectProps={{
                                                        inputProps: {
                                                            'aria-label': 'rows per page',
                                                        },
                                                        native: true,
                                                    }}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    ActionsComponent={TablePaginationActions}
                                                />
                                            </TableRow>
                                        </TableFooter>
                                    </Table>
                                </TableContainer>
                            </div>

                        </div>
                        <div className="mt-8 px-1 block md:hidden ">

                            {
                                (rowsPerPage > 0
                                    ? tabData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : tabData
                                ).map((tdItem, i) => {
                                    return (
                                        <div className="mt-3  ">
                                            <div className="flex  flex-col justify-center gap-5 items-center bg-white rounded-[20px] shadow-md  p-3 mt-4"
                                                style={{ border: '1px solid #d9d9d9', }}>
                                                <div className="flex flex-row justify-start gap-5 items-start w-full" style={{ gap: '15px' }}>
                                                    <div style={{ minWidth: '130px' }}>Date devis</div>
                                                    <div>{changeDateFormatFrench(tdItem.invoice_json_parse.devis_date)}</div>
                                                </div>
                                                <div className="flex flex-row justify-start gap-5 items-start w-full" style={{ gap: '15px' }}>
                                                    <div style={{ minWidth: '130px' }}>N°Facture</div>
                                                    <div>  {tdItem.invoice_json_parse.invoice_number}</div>
                                                </div>
                                                <div className="flex flex-row justify-start gap-5 items-start w-full" style={{ gap: '15px' }}>
                                                    <div style={{ minWidth: '130px' }}>Nom du client</div>
                                                    <div>  {tdItem.messages_json_parse.nom} {tdItem.messages_json_parse.prenom}</div>
                                                </div>
                                                <div className="flex flex-row justify-start gap-5 items-start w-full" style={{ gap: '15px' }}>
                                                    <div style={{ minWidth: '130px' }}>Prestation</div>
                                                    <div>{tdItem.saveType === 'save' ? `Enregistré` : `Envoyé`}</div>
                                                </div>
                                                <div className="flex flex-row justify-start gap-5 items-start w-full" style={{ gap: '15px' }}>
                                                    <div style={{ minWidth: '130px' }}>Etat</div>
                                                    <div>{tdItem.invoice_json_parse.quote_arr[0].prestation}</div>
                                                </div>
                                                <div className="flex flex-row justify-start gap-5 items-start w-full" style={{ gap: '15px' }}>
                                                    <div style={{ minWidth: '130px' }}>Montant</div>
                                                    <div>{convertNumberFrench(tdItem.invoice_json_parse.total_ttc)} €</div>
                                                </div>
                                                <div className="flex flex-row justify-start gap-5 items-center w-full" style={{ gap: '15px' }}>
                                                    <div style={{ minWidth: '130px' }}>Actions </div>
                                                    <Stack spacing={1} direction="row" divider={<Divider orientation="vertical" flexItem />} >
                                                        <Item className='shadow-none bg-transparent cursor-pointer' >
                                                            {/* <BiEuro size={25} color={tdItem.status === "2" || tdItem.status === 2 ? 'red' : 'green'} /> */}
                                                            <Link target={"_blank"} alt={tdItem.invoice_json_parse.invoice_number + ".pdf"} to={process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE + 'assets/uploads/invoices_pro/customers/' + tdItem.invoice_json_parse.invoice_number + ".pdf"}>
                                                                <Down />

                                                            </Link>
                                                        </Item>
                                                        <Item className='shadow-none bg-transparent cursor-pointer' >
                                                            <Edits onClick={(e) => editQuote(e, tdItem)} />
                                                        </Item>
                                                    </Stack>
                                                </div>



                                            </div>
                                        </div>
                                    )
                                })}


                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                        count={tabData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page',
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>

                        </div>
                    </div>

                    {/* QUOTE PAGE */}
                    <div className={!isOpenInvoice && isOpenQuote ? `xl:max-w-[1126px] w-full h-full bg-white md:rounded-[20px]` : `xl:max-w-[1126px] w-full h-full bg-white md:rounded-[20px] hidden`}>
                        <div className='mainHead min-h-[80px] sm:min-h-[120px] flex sm:flex-row flex-col sm:items-center p-3 sm:p-10 gap-4 sm:justify-between'>
                            <div>
                            <h1 className="text-lg sm:text-xl md:text-lg xl:text-[22px] 2xl:text-[22px] font-extrabold text-white sm:mt-0 mt-4">Factures</h1>
                            </div>
                            <div className='w-full sm:w-auto flex sm:justify-end'>
                                {/* {/ Your search component /} */}
                                <Button variant="outlined" className="border-white text-white font-semibold  focus:ring-4 focus:outline-none rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 mb-2" onClick={() => setIsOpenQuote(false)}>Retour à la conversation</Button>
                            </div>
                        </div>
                        <div className="h-auto sm:h-[651px] ">
                            <div className="w-full  h-auto overflow-y-auto p-4 rounded">
                                <div class="border border-gray-500 p-5 rounded-2xl" style={{ borderColor: '#797979' }}>
                                    <Stack
                                        direction={{ xs: 'column', sm: 'row' }}
                                        justifyContent="space-between"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Box component="section" sx={{ p: { xs: 0, sm: 2 }, border: '0px', width: '100%' }} className={"flex "}>

                                            <Stack
                                                direction="column"
                                                justifyContent="space-between"
                                                alignItems="start"
                                                spacing={{ xs: 2, sm: 2 }}

                                            >
                                                <Stack
                                                    direction={{ xs: 'column', sm: 'row' }}

                                                    justifyContent="space-between"
                                                    alignItems="start"
                                                    spacing={2}

                                                >
                                                    <Typography variant="subtitle1" component="subtitle1" className={"font-bold flex items-center"}>
                                                        Factures n°
                                                        <Button variant="outlined" className={"font-bold rounded-full border-gray text-black sn:ml-5 ml-2 "}>{invoiceFormData.invoice_number}</Button>
                                                    </Typography>

                                                </Stack>
                                                <Stack
                                                    direction="column"
                                                    justifyContent="space-between"
                                                    alignItems="start"
                                                    spacing={2}
                                                >
                                                    <Typography variant="body1" className="text-sm m-0 flex items-center" sx={{ color: '#737373' }} >Date du factures  :
                                                        {/* {quoteFormData.devis_date} */}
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText} className={"ml-3 mb-3"}>
                                                            <DatePicker
                                                                className='ml-3'
                                                                sx={{
                                                                    "& .MuiInputLabel-root": { color: '#797979', },
                                                                    "& fieldset": { border: '1px solid #eee' },
                                                                }}
                                                                disablePast={isEditing ? false : true}
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(invoiceFormData.devis_date)}
                                                                /* onChange={(date) => {
                                                                setQuoteFormData(prevState => ({
                                                                    ...prevState,
                                                                    devis_date: date.$d
                                                                }));
                                                                }} */
                                                                onChange={(newValue) => {
                                                                    setInvoiceFormData(prevState => ({
                                                                        ...prevState,
                                                                        devis_date: newValue ? newValue.toISOString() : null
                                                                    }));
                                                                }}
                                                                renderInput={(params) => <TextField {...params} fullWidth variant='outlined' margin='normal' size='small' />}
                                                                inputFormat="DD-MM-YYYY"
                                                            />
                                                        </LocalizationProvider>
                                                    </Typography>
                                                    <Typography variant="body1" className="text-sm  mt-3 flex items-center" sx={{ color: '#737373' }}>Date de validité du factures :
                                                        {/* {invoiceFormData.devis_validity_date} */}
                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText} className={"ml-3 mb-3"}>
                                                            <DatePicker
                                                                className='ml-3'
                                                                disablePast={isEditing ? false : true}
                                                                sx={{
                                                                    "& .MuiInputLabel-root": { color: '#797979', },
                                                                    "& fieldset": { border: '1px solid #eee' },
                                                                }}
                                                                slotProps={{ textField: { size: 'small' } }}
                                                                value={dayjs(invoiceFormData.devis_validity_date)}
                                                                onChange={(newValue) => {
                                                                    setInvoiceFormData(prevState => ({
                                                                        ...prevState,
                                                                        devis_validity_date: newValue ? newValue.toISOString() : null
                                                                    }));
                                                                }}
                                                                /* onChange={(date) => {
                                                                setQuoteFormData(prevState => ({
                                                                    ...prevState,
                                                                    devis_date: date.$d
                                                                }));
                                                                }} */
                                                                renderInput={(params) => <TextField {...params} fullWidth variant='outlined' margin='normal' size='small' />}
                                                                inputFormat="DD-MM-YYYY"
                                                            />
                                                        </LocalizationProvider>
                                                    </Typography>
                                                </Stack>
                                            </Stack>
                                        </Box>
                                        <Box component="section" sx={{
                                            backgroundColor: '#f6f6f6',
                                            borderRadius: '10px',
                                            p: { xs: 2, sm: 3 },
                                            width: {xs: '100%', sm:'50%'}

                                        }}>
                                            <Typography variant="body1" sx={{ color: '#2a2a2a' }} >{messagesData?.society}</Typography >
                                            <Typography variant="body1" className='text-xs capitalize' sx={{ color: '#737373' }} >{messagesData.nom} {messagesData.prenom} <br /> {messagesData.address} <br />{messagesData.code_postal}  {messagesData.ville} </Typography >

                                        </Box>
                                    </Stack>
                                    <Divider className='mt-2 mb-2' />
                                    <Grid container spacing={2} className='h-auto mb-5'>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" sx={{ color: '#2a2a2a' }} className='mb-3 mt-3' >Informations personnelles du client </Typography >
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} sm={3}>
                                                    <Typography variant="body1" sx={{ color: '#2a2a2a' }} className='mb-3 mt-3' >Société</Typography >

                                                    <TextField className={"w-full rounded-full mb-3"} size="small" id="outlined-basic" variant="outlined" placeholder='Société' name='society' value={messagesData.society} onInput={(e) => handleChangeUser(e)} />

                                                </Grid>
                                                <Grid item xs={6} sm={3}>
                                                    <Typography variant="body1" className='mb-3  mt-3' sx={{ color: '#2a2a2a' }} >Prénom</Typography >
                                                    <TextField className={"w-full rounded-full"} size="small" id="outlined-basic" variant="outlined" name='prenom' value={messagesData.prenom} onInput={(e) => handleChangeUser(e)} />

                                                </Grid>
                                                <Grid item xs={6} sm={3}>
                                                    <Typography variant="body1" className='mb-3  mt-3' sx={{ color: '#2a2a2a' }} >Nom</Typography >
                                                    <TextField className={"w-full rounded-full"} size="small" id="outlined-basic" variant="outlined" name='nom' value={messagesData.nom} onInput={(e) => handleChangeUser(e)} />
                                                </Grid>
                                                <Grid item xs={6} sm={3}>
                                                    <Typography variant="body1" className='mb-3  mt-3' sx={{ color: '#2a2a2a' }} >Address</Typography >
                                                    <TextField type="text" size="small" sx={{ borderRadius: '100px' }} variant="outlined" name='address' value={messagesData.address} onInput={(e) => handleChangeUser(e)} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6} sm={3}>
                                                    <Typography variant="body1" className='mb-3  mt-3' sx={{ color: '#2a2a2a' }} >Code postal</Typography >
                                                    <TextField type="text" size="small" sx={{ borderRadius: '100px' }} variant="outlined" name='code_postal' value={messagesData.code_postal} onInput={(e) => handleChangeUser(e)} />
                                                </Grid>
                                                <Grid item xs={6} sm={3} >
                                                    <Typography variant="body1" className='mb-3  mt-3' sx={{ color: '#2a2a2a' }} >Ville</Typography >
                                                    <Select
                                                        size="small"
                                                        className='w-full'
                                                        helperText=""
                                                        // error={(submitted && !formData.ville_id) ?true:false}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Ville"
                                                        name='ville_id'
                                                        value={messagesData.ville_id}
                                                        onChange={handleChangeUser}
                                                    >
                                                        {
                                                            messagesData.villeArr.map((cityVal, cityIndex) => {
                                                                return (<MenuItem key={cityIndex} value={cityVal.id}>{cityVal.city}</MenuItem>)
                                                            })
                                                        }
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={6} sm={3}>
                                                    <Typography variant="body1" className='mb-3  mt-3' sx={{ color: '#2a2a2a' }} >Téléphone</Typography >
                                                    <TextField type="text" size="small" sx={{ borderRadius: '100px' }} variant="outlined" name='telephone' value={messagesData.telephone} onInput={(e) => handleChangeUser(e)} />
                                                </Grid>
                                                <Grid item xs={6} sm={3}>
                                                    <Typography variant="body1" className='mb-3  mt-3' sx={{ color: '#2a2a2a' }} >E-mail</Typography >
                                                    <TextField type="text" size="small" sx={{ borderRadius: '100px' }} variant="outlined" name='email' value={messagesData.email} onInput={(e) => handleChangeUser(e)} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Divider className='mt-2 mb-2' />
                                    <Grid container spacing={2} className='min-h-[300px]  '>
                                        {
                                            invoiceFormData.quote_arr.map((prest, index) => {
                                                return (
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} sm={4}>
                                                                <Typography variant="body1" sx={{ color: '#2a2a2a' }} className='mb-3 mt-3' >Prestations</Typography >

                                                                <TextField className={"w-full rounded-full mb-3"} size="small" id="outlined-basic" variant="outlined" placeholder='Description de la prestation' value={prest.prestation} name='prestation' onInput={(e) => handleChange(e, index)} />

                                                            </Grid>
                                                            <Grid item xs={12} md={7}>
                                                                <Grid container spacing={2} className="flex justify-end items-center">
                                                                    <Grid item xs={12} sm={4}>
                                                                        <Typography variant="body1" className='mb-3  mt-3' sx={{ color: '#2a2a2a' }} >Quantité</Typography >
                                                                        <TextField type="number" className={"w-full rounded-full"} size="small" id="outlined-basic" variant="outlined" value={prest.quantity} name='quantity' onInput={(e) => handleChange(e, index)} />

                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <Typography variant="body1" className='mb-3  mt-3' sx={{ color: '#2a2a2a' }} >Prix unit. HT</Typography >
                                                                        <TextField type="number" size="small" className={"w-full rounded-full"} sx={{ borderRadius: '100px', }} variant="outlined" InputProps={{
                                                                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                                        }} value={prest.price_ht} name='price_ht' onInput={(e) => handleChange(e, index)} />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={4}>
                                                                        <Typography variant="body1" className='mb-3  mt-3' sx={{ color: '#2a2a2a' }} >Total HT</Typography >
                                                                        <TextField type="text" className={"w-full rounded-full"} size="small" sx={{ borderRadius: '100px' }} variant="outlined" InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment>, readOnly: true, }} value={prest.sub_total_ht} name='sub_total_ht' onInput={(e) => handleChange(e, index)} />
                                                                    </Grid>

                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} sm={1} className="mt-7 flex items-center" >
                                                                <RemoveCircleOutlineOutlinedIcon sx={{ fontSize: 25 }} className={index === 0 ? `hidden` : `ml-2 cursor-pointer`} onClick={(e) => handleRemove(e, "prestation", index)} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                        <Typography variant="body1" sx={{ color: '#2a2a2a' }} className='pl-5 pr-5 mb-1 mt-1 cursor-pointer' onClick={(e) => handleAdd(e, "prestation")}><ControlPointOutlinedIcon sx={{ fontSize: 25, }} className='mr-2' />
                                            Ajouter une prestation
                                        </Typography>
                                    </Grid>
                                    <Divider className='mt-2 mb-2' />
                                    <Grid container spacing={2} >
                                        <Grid item xs={0} sm={6} className='hidden sm:block'>

                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Grid container spacing={2} alignItems="center" className="mb-3 flex justify-end items-center">
                                                <Grid item xs={12} className="flex justify-end items-center ">
                                                    <Typography variant="body1" sx={{ color: '#2a2a2a', mr: 2 }}>
                                                        Total HT:
                                                    </Typography>
                                                    <TextField
                                                        className='w-[150px] '
                                                        size="small"
                                                        sx={{ borderRadius: '100px' }}
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                            readOnly: true,
                                                        }}
                                                        name={"total_ht"}
                                                        value={invoiceFormData.total_ht}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} alignItems="center" className="mb-3  flex justify-end items-center">
                                                <Grid item xs={12} className="flex justify-end items-center">
                                                    <Typography variant="body1" className='flex items-center' sx={{ color: '#2a2a2a', mr: 2 }}>
                                                        <Checkbox checked={invoiceFormData.remiseIsChecked} onChange={handleChecked} /> Remise
                                                    </Typography>
                                                    <TextField
                                                        className='w-[150px] '
                                                        size="small"
                                                        sx={{ borderRadius: '100px' }}
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                                            readOnly: true,
                                                        }}
                                                        name={"remise"}
                                                        value={parseFloat(invoiceFormData.remise)}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2} alignItems="center" className={invoiceFormData.remiseIsChecked ? "mb-3  flex justify-end items-center" : "hidden"} >
                                                <Grid item xs={12} className="flex justify-end items-center">
                                                    <Typography variant="body1" sx={{ color: '#2a2a2a', mr: 2 }}>
                                                        Tolal Remise HT
                                                    </Typography>
                                                    <TextField
                                                        className='w-[150px] '
                                                        size="small"
                                                        sx={{ borderRadius: '100px' }}
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                            readOnly: true,
                                                        }}
                                                        name={"total_remise_ht"}
                                                        value={parseFloat(invoiceFormData.total_remise_ht)}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2} alignItems="center" className="mb-3  flex justify-end items-center">
                                                <Grid item xs={12} className="flex justify-end items-center">
                                                    <Typography variant="body1" sx={{ color: '#2a2a2a', mr: 2, textWrap: 'nowrap' }}>
                                                        Total TVA ({currentUser && currentUser.UserBusinessDetails && currentUser.UserBusinessDetails.companyArr.vat ? parseFloat(currentUser.UserBusinessDetails.companyArr.vat) : 0}%)
                                                    </Typography>
                                                    <TextField
                                                        className='w-[150px] '
                                                        size="small"
                                                        sx={{ borderRadius: '100px' }}
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                            readOnly: true,
                                                        }}
                                                        name={"total_tva"}
                                                        value={parseFloat(invoiceFormData.total_tva).toFixed(2)}
                                                    />
                                                </Grid>

                                            </Grid>
                                            <Grid container spacing={2} alignItems="center" className="mb-3  flex justify-end items-center">
                                                <Grid item xs={12} className="flex justify-end items-center">
                                                    <Typography variant="body1" sx={{ color: '#2a2a2a', mr: 2 }}>
                                                        Total TTC
                                                    </Typography>
                                                    <TextField
                                                        className='w-[150px] '
                                                        size="small"
                                                        sx={{ borderRadius: '100px' }}
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                            readOnly: true,
                                                        }}
                                                        name={"total_ttc"}
                                                        value={invoiceFormData.total_ttc}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* footer part */}

                                    <div item xs={12} sm={6} className="mb-3 mt-4 flex justify-between items-center 
                                flex-col sm:flex-row
                                
                                gap-4">
                                        <Link target={"_blank"} className='w-full' alt={invoiceFormData.invoice_number + ".pdf"} to={process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE + 'assets/uploads/invoices_pro/customers/' + invoiceFormData.invoice_number + ".pdf"}  >
                                            {/*  <a target="_blank" download={false} rel="noopener noreferrer" alt={quoteFormData.devis_number+".pdf"} href={process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE + 'assets/uploads/quotes_pro/customers' + quoteFormData.devis_number+".pdf"} > */}
                                            <Button variant="outlined" className='capitalize' startIcon={<SearchIcon />} sx={{ borderColor: '#27295B', width: '100%', color: '#27295B', '&:hover': { borderColor: '#27295B', backgroundColor: '#27295B', color: '#FFFFFF' } }}   >Aperçu</Button>
                                        </Link>
                                        {/* <Button onClick={(e)=>handleSave(e,"save")} variant="outlined" startIcon={<SaveIcon />}  sx={{ borderColor: '#27295B', color: '#27295B','&:hover': {borderColor: '#27295B',  backgroundColor: '#27295B', color: '#FFFFFF' } }} >Enregistrer sans envoyer</Button>  */}
                                        <LoadingButton onClick={(e) => handleSave(e, "save", "save")} variant="outlined" sx={{ borderColor: '#27295B', color: '#27295B', '&:hover': { borderColor: '#27295B', backgroundColor: '#27295B', color: '#FFFFFF' } }} className=' capitalize  w-full'
                                            // onClick={handleSubmit}
                                            endIcon={<SaveIcon />}
                                            loading={isLoading}
                                            loadingPosition="end" >
                                            <span className='text-nowrap'>Enregistrer sans envoyer</span>
                                        </LoadingButton>


                                        <Button onClick={() => setIsOpenQuote(false)} variant="outlined" className='capitalize  w-full' sx={{ borderColor: '#27295B', color: '#27295B', '&:hover': { borderColor: '#27295B', backgroundColor: '#27295B', color: '#FFFFFF' } }}>Annuler</Button>
                                        {/* <Button onClick={(e)=>handleSave(e,"send")} variant="contained" sx={{backgroundColor: '#27295B', borderColor: '#27295B', color: '#FFFFFF','&:hover': {borderColor: '#27295B',  backgroundColor: '#27295B', color: '#FFFFFF' } }}>Envoyer</Button> */}
                                        <LoadingButton
                                            onClick={(e) => handleSave(e, "send", "send")} variant="outlined" sx={{ borderColor: '#27295B', color: '#27295B', '&:hover': { borderColor: '#27295B', backgroundColor: '#27295B', color: '#FFFFFF' } }}
                                            className=' capitalize   w-full'
                                            endIcon={<SaveIcon />}
                                            loading={isLoadingSend}
                                            loadingPosition="end"                  >
                                            <span className='text-nowrap'>Générer et envoyer</span>
                                        </LoadingButton>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* INVOICE PAGE  */}
                    {/* <InvoiceComp isOpenQuote={isOpenQuote} setIsOpenQuote={setIsOpenQuote} isOpenInvoice={isOpenInvoice} setIsOpenInvoice={setIsOpenInvoice} quoteFormData={quoteFormData}  setQuoteFormData={setQuoteFormData} invoiceFormData={invoiceFormData}  setInvoiceFormData={setInvoiceFormData} isEditing={isEditing} messagesData={messagesData} handleChangeUser={handleChangeUser} handleChange={handleChange} handleRemove={handleRemove} handleAdd={handleAdd} handleSave={handleSave} handleChecked={handleChecked} setIsLoading={setIsLoading} isLoading={isLoading} setIsLoadingSend={setIsLoadingSend} isLoadingSend={isLoadingSend} /> */}
                </div>
            </Layout>
        </>
    )
}

